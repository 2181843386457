<template>
  <div>
    <!-- Generate Alerts -->
    <div v-if="showPopupConfirmation" class="supportBlocker"></div>
    <div
      v-show="showPopupConfirmation"
      class="popUpToShowForEmailSupport w-5/12"
    >
      <!-- Alert Dropdown -->
      <div
        class="sendMail_modal h-full"
        v-show="showPopupConfirmation"
        @click="outSideClicked"
      >
        <div class="Child_Of_sendMail_modal w-5/12">
          <div
            class="p-4 py-2 flex justify-between items-center shadow"
            style="height: 42px"
          >
            <!-- {{ isSendingMailorSMS }} -->
            <div class="flex justify-around" v-if="isSendingMailorSMS">
              <button>Send Alert</button>
            </div>
            <button
              @click="CloseSupportPopUp"
              class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 42px)"
          >
            <div class="emailSubmit_Form" style="width: 100% !important">
              <p>Do you want to send this as Email / SMS / Both</p>
              <div class="flex justify-between mt-8">
                <div>
                  <button
                    style="
                      padding-top: 0.3rem !important;
                      padding-bottom: 0.3rem !important;
                    "
                    class="primary_text primary_border_color border active:bg-blueGray-600 text-xs font-bold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    @click="
                      (showPopupConfirmation = false),
                        (isSendingMailorSMS = true),
                        (alertsTypePopup = true)
                    "
                  >
                    <i class="fas fa-arrow-left mr-1"></i> Back
                  </button>
                </div>
                <div>
                  <button
                    class="ml-3 sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    @click="gotoEmail('email')"
                  >
                    Email
                  </button>
                  <button
                    class="ml-3 sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    @click="gotoEmail('sms')"
                  >
                    SMS
                  </button>
                  <button
                    class="ml-3 sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    @click="gotoEmail('both')"
                  >
                    Both
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Alert Dropdown -->
    </div>
    <!-- Generate Alerts -->

    <!-- Generate Alerts -->
    <div v-if="showUsers" class="supportBlocker"></div>
    <div
      v-show="showUsers"
      class="popUpToShowForEmailSupport userSelectDiv w-5/12"
    >
      <!-- Alert Dropdown -->
      <div
        class="sendMail_modal h-full"
        v-show="showUsers"
        @click="outSideClicked"
      >
        <div class="Child_Of_sendMail_modal w-5/12">
          <div
            class="p-4 py-2 flex justify-between items-center shadow"
            style="height: 42px"
          >
            <!-- {{ isSendingMailorSMS }} -->
            <div class="flex justify-around">
              <button>Select Users</button>
            </div>
            <button
              @click="CloseSupportPopUp"
              class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 92px)"
          >
            <div class="emailSubmit_Form" style="width: 100% !important">
              <!-- <p>Do you want to send this as Email / SMS / Both</p> -->
              <div
                v-for="(user, index) in usersDetails"
                :key="index"
                style="display: flex"
              >
                <div class="ml-0.5 tool_section">
                  <input
                    :key="refreshKey"
                    type="checkbox"
                    :id="'alertswitch' + user.userId"
                    class="tool_toggle_input"
                    v-bind:value="user.userId"
                    v-model="userChecked"
                    @click="selectUser(user)"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="isZoom ? 'muted_bg' : '_alert'"
                    :for="'alertswitch' + user.userId"
                    >Alerts :</label
                  >
                </div>
                <span v-if="selectedType == 'email'">{{ user.userName }}</span>
                <span v-else
                  >{{ user.userName }}({{
                    user.communication.mobileNumber
                  }})</span
                >
              </div>
            </div>
          </div>
          <div class="p-4 py-2 flex justify-between">
            <button
              style="
                padding-top: 0.3rem !important;
                padding-bottom: 0.3rem !important;
              "
              class="primary_text primary_border_color border active:bg-blueGray-600 text-xs font-bold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              @click="(showPopupConfirmation = true), (showUsers = false)"
            >
              <i class="fas fa-arrow-left mr-1"></i> Back
            </button>
            <button
               v-show="userChecked.length>0"
              @click="sendMail"
              class="sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
              type="button"
              :disabled="isSendEmailClicked"
            >
              <span>{{isSendEmailClicked?'Loading....':'Select'}}</span>
            </button>
          </div>
        </div>
      </div>
      <!-- Alert Dropdown -->
    </div>
    <!-- Generate Alerts -->

    <!-- Generate Alerts -->
    <div v-if="alertsTypePopup" class="supportBlocker"></div>
    <div
      v-show="alertsTypePopup"
      class="popUpToShowForEmailSupport w-5/12"
      :style="isSendingMailorSMS ? 'height: 50%' : 'height: 25%'"
      :key="refreshAlertKey"
    >
      <!-- Alert Dropdown -->
      <!-- @click="outSideClickedAlert" -->
      <div class="sendMail_modal h-full" v-show="alertsTypePopup">
        <div class="Child_Of_sendMail_modal w-5/12">
          <div
            class="p-4 py-2 flex justify-between items-center shadow"
            style="height: 42px"
          >
            <!-- {{ isSendingMailorSMS }} -->
            <div class="flex justify-around" v-if="isSendingMailorSMS">
              <button
                class="text-xs p-1 mr-2 rounded"
                :class="{ activeCommuni: selectedOne === 1 }"
                @click="clickedOnCommunication(1)"
              >
                Generate Manual Alerts
              </button>
            </div>
            <div v-else class="flex justify-around">
              <button
                style="width: 84px"
                class="text-xs p-1 mr-2 rounded"
                :class="{ activeCommuni: selectedOne === 1 }"
                @click="clickedOnCommunication(1)"
              >
                Alert Type
              </button>
            </div>
            <button
              @click="CloseSupportPopUp"
              class="text-sm font-normal uppercase text-red_"
              style="color: #cf634b"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 42px)"
            v-if="isSendingMailorSMS"
          >
            <div style="width: 100%" class="emailSubmit_Form">
              <form @submit.prevent="sendAlertEmail" class="flex flex-col">
                <div>
                  <label for="textareaField" class="text-xs fontWeight-600 mb-1"
                    >Alert Name:</label
                  >
                  <input
                    class="w-full"
                    type="text"
                    placeholder="Alert Type"
                    id="from"
                    required
                    disabled
                    style="filter: opacity(0.6); font-weight: 600"
                    v-model="selectedAlertType"
                  />
                </div>
                <label for="textareaField" class="text-xs fontWeight-600 mb-1"
                  >Notes:</label
                >
                <textarea
                  ref="textareaField"
                  class="mb-3"
                  id="textareaField"
                  v-model="alertNate"
                  @change="textArea"
                  required
                ></textarea>
                <label for="textareaField" class="text-xs fontWeight-600 mb-1"
                  >Time:</label
                >
                <p class="noteDateTime">
                  Note: Date & Time should in between {{ showMin }} and {{
                    showMax
                  }}
                </p>
                
                <Datepicker
                  class="dataGapsPopup"
                  v-model="selectedDate"
                  :minDate="
                    new Date(scaleMinFixed).toLocaleString('en-US', {
                      timeZone: this.time_zone,
                    })
                  "
                  :maxDate="
                    new Date(scaleMaxFixed).toLocaleString('en-US', {
                      timeZone: this.time_zone,
                    })
                  "
                  :dark="darkDark != 'white'"
                  textInput
                  placeholder="YYYY-MM-DD H:M:S"
                  :enableTimePicker="true"
                  :enable-seconds="true"
                  :timezone="this.time_zone ? this.time_zone : null"
                  style="width: 100% !important"
                  required
                />
                <div class="flex justify-between mt-3">
                  <button
                    style="
                      padding-top: 0.3rem !important;
                      padding-bottom: 0.3rem !important;
                    "
                    class="primary_text primary_border_color border active:bg-blueGray-600 text-xs font-bold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                    @click="isSendingMailorSMS = false"
                  >
                    <i class="fas fa-arrow-left mr-1"></i> Back
                  </button>
                  <button
                    class="items-end sendMailbtn bgClr text-white text-xs font-semibold uppercase px-4 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    type="submit"
                    :disabled="mail_Sending"
                  >
                    <span>{{ mail_Sending ? "Sending..." : "Send" }}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="w-full flex px-5 py-3 overflow-auto"
            style="gap: 30px; height: calc(100% - 42px)"
            v-else
          >
            <div style="width: 100%" class="emailSubmit_Form">
              <form @submit.prevent="selectAlertType" class="flex flex-col">
                <label for="selectSubEntity" class="text-xs fontWeight-600 mb-1"
                  >Choose Alert Type</label
                >
                <!-- {{ selectedAlertType }} -->
                <div class="px-1 w-12/12 relative flex">
                  <!-- <label for="wellSection" class="block uppercase dark_text text-xs font-bold mb-2">Choose Alert
                  Type</label> -->
                  <select
                    @change="selectedAlerrt"
                    id="wellSection"
                    class="w-full mt-1 pl-1 block py-2 mx-8 border border-gray-300 dark_primary rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs ml-0 w-12/12"
                    placeholder="Status"
                    v-model="selectedAlertType"
                  >
                    <option value="" disabled>Select</option>
                    <option
                      :key="index"
                      v-for="(bitsize, index) in generateAlerts"
                      :value="bitsize"
                    >
                      {{ bitsize }}
                    </option>
                    <option value="Other">Other</option>
                  </select>
                  <div
                    v-if="otherSelected"
                    class="newWellSec absolute top-0 left-0 px-1 w-8/12 text-box-append"
                  >
                    <input
                      placeholder="Type New Alert Type here..."
                      ref="addNewAlertType"
                      class="mt-1 block dark_primary dark_text py-2 mx-8 border-0 rounded-md focus:outline-none focus:border-none sm:text-xs ml-0 w-full"
                      type="text"
                      min="0"
                      v-model.lazy="selectedAlertType"
                      required
                    />
                  </div>
                  <button
                    v-if="otherSelected || selectedAlertType.length > 0"
                    type="submit"
                    class="show_Cc_Btn text-xs h-full font-semibold ml-2 mt-2 px-1 py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                    @click="movetoNext()"
                  >
                    Select
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Alert Dropdown -->
    </div>
    <!-- Generate Alerts -->

    <div
      v-if="isPartialClicked"
      class="wraperForParialScreenshot"
      @mousedown="startSelection"
      @mouseup="endSelection"
      @mousemove="updateSelection"
    >
      <div
        v-show="isMouseDown"
        id="capture-area"
        class="overlay"
        :style="{
          left: selection.left + 'px',
          top: selection.top + 'px',
          width: selection.width + 'px',
          height: selection.height + 'px',
        }"
      ></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import configjs from "../../../config";
import html2canvas from "html2canvas";
import Datepicker from "@vuepic/vue-datepicker";
import api from "../../../api/services";
import moment from "moment-timezone";
export default {
  name: "GenerateAlertPopup",
  components: {
    Datepicker,
  },
  setup() {},
  props: {
    CloseSupportPopUp: Function,
    displayId: String,
    selectedWellbore: String,
    selectedWell: String,
    dWell: String,
    selectLog: Function,
    showMin: String,
    showMax: String
  },
  data() {
    return {
      selectedType: "",
      isSupportScreenshotShowing: true,
      payload: {
        wellId: "",
        wellBoreName: "",
        time: "",
        emailRecipients: "",
        mobileRecipients: [],
        alert: "",
        body: "",
        communicationTypes: [],
        wellName: "",
      },
      refreshAlertKey: 10,
      capturedImage: "",
      selectedAlertType: [],
      showPopupConfirmation: false,
      isSendingMailorSMS: false,
      showUsers: false,
      usersDetails: [],
      alertsTypePopup: false,
      selectedOne: 1,
      otherSelected: false,
      isPartialClicked: false,
      generateAlerts: [],
      selectedDate: "",
      topicsName: "",
      currentTimeRange: 1,
      scaleMin: 0,
      scaleMax: 1000,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      scaleMinDate: "",
      scaleMaxDate: "",
      allUsersSelected1: false,
      allUsersSelected2: false,
      allUsersSelected3: false,
      selectedOptouser: [],
      selectedOpSub: [],
      selectedOpRole: [],
      cusEmail: "",

      cc: "",
      selectedUsersForMo: [],
      tousers: [],
      mail_Sending: false,
      customMobileNumArray: [],
      selectedUsersMobileNum: [],
      selectedSubE: [],
      selectedRT: [],
      tab: "",
      usersData: [],
      roleType: [],
      subEntities: [],
      selectedUsers: [],
      chooseRoleType: false,
      selectUserOf: false,
      selectTo: false,
      chooseSubEn: false,
      DiscribeText: "",
      subject: "",
      customMailsArray: [],
      allUsersSelected: false,
      showAllMailsFromParent1: false,
      showAllMailsFromParent2: false,
      showAllMailsFromParent3: false,
      selectedOption: [],
      selectedOptionOfSubEntities: [],
      reqOptions: [
        "salman",
        "syam",
        "sampath",
        "aslam",
        "kavitha",
        "asis",
        "prerana",
        "prajaktha",
        "akshay",
      ],
      selectedOne: 1,
      showCC_Ip: false,
      payload: {
        wellId: "",
        wellBoreName: "",
        time: "",
        emailRecipients: [],
        mobileRecipients: [],
        alert: "",
        body: "",
        communicationTypes: [],
        wellName: "",
      },
      refreshAlertKey: 10,
      userChecked: [],
      displayName: null,
      userChecked: [],
      displayName: null,
    };
  },
  async mounted() {
    try {
      let obj = {
        type: "CRITICAL_CONDITION_ALERTS",
      };
      let response = await api.AlertServices.gettingAlerts(obj);
      if (response) {
        this.generateAlerts = response.data;
        this.alertsTypePopup = true;
        this.isSendingMailorSMS = false;
        this.generateAlerts = this.generateAlerts.map((alert) => {
          if (alert === "INFLUXDETECTION") {
            return "INFLUX DETECTION";
          } else if (alert === "POORHOLECLEAN") {
            return "POOR HOLE CLEAN";
          } else if (alert === "INCREASEINECD") {
            return "INCREASE IN ECD";
          } else if (alert === "T&DDARGANALYSIS") {
            return "T&D DRAG ANALYSIS";
          } else {
            return alert;
          }
        });
      }
      let rectForSavedName = this.$store.state.disp.rects.find(
        (rect) => rect.displayId == this.displayId
      );
      if (rectForSavedName) {
        this.displayName = rectForSavedName.savedName;
      } else {
        this.displayName = null;
      }
    } catch (error) {
      console.log(
        "🚀 ➽ file: GenerateAlert.vue:268  ➽ mounted  ➽ error ⏩",
        error
      );
    }
  },
  beforeUnmount() {
    this.refreshAlertKey += 1;
  },
  methods: {
    async deleteCache(){
      await api.WellServices.deleteLokiCacheDetails(
        this.displayName ? this.displayName.replace(/\s/g, "").toLowerCase(): null,
        this.selectedWell,
        this.selectedWellbore,
        'alert'
      );
  
    },
    updateSelection(event) {
      event.stopPropagation();
      if (this.isSelecting) {
        this.endX = event.clientX;
        this.endY = event.clientY;
        this.selection.left = Math.min(this.startX, this.endX);
        this.selection.top = Math.min(this.startY, this.endY);
        this.selection.width = Math.abs(this.endX - this.startX);
        this.selection.height = Math.abs(this.endY - this.startY);
      }
    },
    endSelection(event) {
      this.isMouseDown = false;
      this.isSelecting = false;
      this.endX = event.clientX;
      this.endY = event.clientY;
      this.isMouseDown = false;
      this.isPartialClicked = false;
      const captureElement = document.getElementById("app");
      html2canvas(captureElement).then((canvas) => {
        const croppedCanvas = document.createElement("canvas");
        croppedCanvas.width = this.selection.width;
        croppedCanvas.height = this.selection.height;
        const ctx = croppedCanvas.getContext("2d");
        ctx.drawImage(
          canvas,
          this.selection.left,
          this.selection.top,
          this.selection.width,
          this.selection.height,
          0,
          0,
          this.selection.width,
          this.selection.height
        );
        // ctx.drawImage(captureElement, this.startX, this.startY, this.selection.width, this.selection.height, 0, 0, this.selection.width, this.selection.height);

        // // Display the cropped image on the page
        const dataURL = croppedCanvas.toDataURL();
        this.capturedImage = dataURL;
        this.isSupportScreenshotShowing = true;
        console.log(
          "capturedurl.....",
          this.capturedUrl,
          dataURL,
          this.selection.width,
          this.selection.height
        );
      });
    },
    startSelection(event) {
      this.isMouseDown = true;
      this.isSelecting = true;
      this.startX = event.clientX;
      this.startY = event.clientY;
    },
    outSideClicked(event) {
      let popUpSub = document.querySelector(".sub__");
      let popUpRole = document.querySelector(".role__");
      let popUpUser = document.querySelector(".users__");
      if (this.showAllMailsFromParent1) {
        if (!popUpSub.contains(event.target)) {
          this.showAllMailsFromParent1 = false;
        }
      } else if (this.showAllMailsFromParent2) {
        if (!popUpRole.contains(event.target)) {
          this.showAllMailsFromParent2 = false;
        }
      } else if (this.showAllMailsFromParent3) {
        if (!popUpUser.contains(event.target)) {
          this.showAllMailsFromParent3 = false;
        }
      }
    },
    selectAlertType() {
      this.isSendingMailorSMS = true;
    },
    selectedAlerrt(event) {
      this.selectedAlertType = event.target.value;
      // console.log(altertType, "al");
      if (this.selectedAlertType === "Other") {
        console.log(",njhjbhjghbh");
        this.selectedAlertType = "";
        this.otherSelected = true;
      }
    },
    async sendAlertEmail() {
      this.alertsTypePopup = false;
      let id = localStorage.getItem("selectedEntityId");

      try {
        let response = await api.SupportServices.getUsersEnitity(id);
        // this.data = response;
        console.log("get tutorial", response.data.length);
        this.usersDetails = response.data;
        if (this.usersDetails.length > 0) {
          this.showPopupConfirmation = true;
        } else {
          this.$toast.error("No Uers Found", {
            duration: 2000,
            position: "top",
          });
        }

        // console.log("user_address useradd 1", this.user_address);
        // if (response && response.data) {
        //   this.communication__ = this.data.communication

        // }
      } catch (error) {
        console.error(error);
      }
      // },
    },
    gotoEmail(type) {
      this.payload.communicationTypes = [];
      this.payload.mobileRecipients = [];
      this.payload.emailRecipients = [];
      this.userChecked = [];
      this.refreshKey += 1;
      this.showUsers = true;
      this.selectedType = type;
      console.log(type, "type");
      if (type === "both") {
        this.payload.communicationTypes.push("email");
        this.payload.communicationTypes.push("sms");
      } else if (type === "sms") {
        this.payload.communicationTypes.push("sms");
      } else if (type === "email") {
        this.payload.communicationTypes.push("email");
      }
      // console.log(payload, "payloadpayload");
    },
    outSideClickedAlert() {
      this.alertsTypePopup = true;
      console.log("snmbhjsbf", this.alertsTypePopup);
    },
    selectUser(user) {
      console.log(user, "usr");
    },
    async getMinMaxRange() {
      try {
        let result = null;
        result = await api.WellServices.getMinMaxTime({
          well_id: this.selectedWell,
          wellbore_name: this.selectedWellbore,
        });
        console.log(
          "time zone difference : ",
          result.data.max,
          moment(result.data.max).unix() * 1000
        );
        result.data.min = moment(result.data.min).unix() * 1000;
        result.data.max = moment(result.data.max).unix() * 1000;

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async movetoNext() {
      this.currentTimeRange = 1;
      let dtls = this.$store.state.disp.displays[this.displayId];
      console.log(dtls, "dtls");
      this.time_zone = dtls.time_zone || "CST6CDT";
      moment.tz.setDefault(this.time_zone);

      let minMax = await this.getMinMaxRange();
      // this.scaleMin = minMax.max - 14400000;
      console.log(minMax, "minmax");
      this.scaleMin =
        minMax.max - 3600000 > minMax.min ? minMax.max - 3600000 : minMax.min;
      this.scaleMax =
        minMax.max - 3600000 > minMax.min ? minMax.max : minMax.min + 3600000;
      this.scaleMinFixed = minMax.min;
      this.scaleMaxFixed = minMax.max;
      
      this.scaleMinDate = new Date(this.scaleMin);
      this.scaleMaxDate = new Date(this.scaleMax);
      // this.selectedDate = this.scaleMaxDate;
      this.selectedDate = new Date(minMax.max).toLocaleString('en-US', {
                      timeZone: this.time_zone,
                    })
    },
    getText() {
      console.log(this.selectedAlertType, "slected alert type");
    },
    goBack() {},
    async sendMail() {
      if (this.isSendEmailClicked) {
        return true;
      } else {
        this.isSendEmailClicked = true;

        try {
          let intialTime = new Date(this.selectedDate);

          // let dd = moment.utc(moment(this.selectedDate)).format();
          let dd = moment(new Date(this.selectedDate).toISOString().slice(0, 16)).unix() * 1000 - new Date().getTimezoneOffset()*60*1000;
          this.showPopupConfirmation = false;
          this.showUsers = true;
          this.userChecked.filter((checkedUser) => {
            this.usersDetails.filter((user) => {
              console.log(checkedUser, "fgdg=",this.selectedType, user.userId);
              if (checkedUser == user.userId) {
                if (this.selectedType == "sms") {
                  this.payload.mobileRecipients.push(
                    user.communication.mobileNumber
                  );
                } else if (this.selectedType == "email") {
                  this.payload.emailRecipients.push(user.userName);
                } else {
                  this.payload.emailRecipients.push(user.userName);
                  this.payload.mobileRecipients.push(
                    user.communication.mobileNumber
                  );
                }
              }
            });
          });
          //delete cache before saving generate alert;
          await this.deleteCache();

          this.payload = {
            wellId: this.selectedWell,
            wellBoreName: this.selectedWellbore,
            time: dd,
            emailRecipients: this.payload.emailRecipients,
            mobileRecipients: this.payload.mobileRecipients,
            alert: this.selectedAlertType,
            body: this.alertNate != null ? this.alertNate : "test",
            communicationTypes: this.payload.communicationTypes,
            wellName: this.dWell,
          };

          axios
            .post(
              configjs.HOST_URL.APOLLO_UTIL_URL + "alertsemailsms/manual/send",
              this.payload
            )
            .then((response) => {
              console.log("coming  here", response);
              this.showUsers = false;
              this.CloseSupportPopUp();
              if (response.status === 200) {
                this.$toast.success(response.data.message, {
                  duration: 2000,
                  position: "top",
                });
                this.selectLog('time');
              }
              this.isSendEmailClicked = false;
            })
            .catch((err) => {
              console.log("error after send : ", err)
              this.isSendEmailClicked = false;
              this.$toast.error(`${err}`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
              this.CloseSupportPopUp();
            });
        } catch (error) {
          this.isSendEmailClicked = false;
          this.CloseSupportPopUp();
        }
      }
    },
    async deleteCache(){
      await api.WellServices.deleteLokiCacheDetails(
        this.displayName ? this.displayName.replace(/\s/g, "").toLowerCase(): null,
        this.selectedWell,
        this.selectedWellbore,
        'alert'
      );
  
    },
  },
};
</script>
<style scoped>
.Child_Of_sendMail_modal {
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}

.fontWeight-600 {
  font-weight: 600;
}

.emailSubmit_Form input {
  border: none;
  border-radius: 3px;
  left: 0;
  height: 30px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
}

.emailSubmit_Form select {
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}

.emailSubmit_Form textarea {
  border: none;
  border-radius: 3px;
  /* font-size: 13px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  /* background: var(--searchBarBg); */
  /* color: var(--searchColor); */
  background: var(--sidebarListBgColor);
  color: var(--textColor);
  padding: 2px 6px;
  outline: none;
}

.emailSubmit_Form {
  overflow-y: auto;
  padding-right: 12px;
}

.selectedOptions {
  border: none;
  border-radius: 3px;
  left: 0;
  /* height: 50px; */
  min-height: 30px;
  max-height: 50px;
  font-size: 13px;
  padding: 0px 0px 0px 2px;
  overflow-y: auto;
  flex-wrap: wrap;
  appearance: auto;
  /* background: var(--searchBarBg); */
  background: var(--sidebarListBgColor);
  color: var(--searchColor);
}

.show_Cc_Btn {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.sendMailbtn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.bgClr {
  background: var(--activeTextColor);
}

.activeCommuni {
  border-bottom: 2px solid var(--activeTextColor);
  border-radius: 0;
  color: var(--activeTextColor);
  font-size: 15px;
  transition: all 0.01s ease-in;
}

.selectedOption {
  background-color: #b4b5ba15;
  /* padding:1px;  */
  font-size: 10px;
  padding-left: 6px;
  padding-right: 6px;
  height: 22px;
}

.listDiv {
  position: absolute;
  z-index: 9999999999;
  background: black;
  width: 100%;
}

.downArrowOfSelect {
  /* position:absolute; */
  position: absolute;
  top: 10px;
  right: 5px;
  margin-left: 5px;
}

.onlyEmail {
  color: var(--activeTextColor);
}

.show_Cc_Btn {
  color: var(--activeTextColor);
  border: 1px solid currentColor;
}

.sendMailbtn[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.bgClr {
  background: var(--activeTextColor);
}

.activeCommuni {
  border-bottom: 2px solid var(--activeTextColor);
  border-radius: 0;
  color: var(--activeTextColor);
  font-size: 15px;
  transition: all 0.01s ease-in;
}

.onlyEmail {
  color: var(--activeTextColor);
}

/* //customized css */
.popUpToShowForEmailSupport {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 75%; */
  height: 25%;
  z-index: 9999999;
  background: var(--sidebarbg);
  /* padding:10px; */
}

.userSelectDiv {
  height: 40% !important;
}

.text-box-append {
  top: 0px;
  padding-left: 5px;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}

.noteDateTime {
  font-size: 10px;
  color: #f15b5b;
}

.dataGapsPopup {
  width: 100% !important;
}

.dataGapsPopup input {
  width: 150px;
  height: 38px;
}

.backg-color {
  background: #3d5d6d !important;
}

.eye_icon {
  color: var(--textColor);
}

.dp__time_col_sec {
  padding: 0px 5px !important;
}

.dp__input {
  width: 100% !important;
}
.bg_for_root_cross {
  background: var(--root_nav);
}
</style>

